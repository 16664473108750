import { ContentCreationData, Heading } from 'components/atoms/typography';

import { AppPageProps } from 'gatsby/types';
import { Container } from 'components/atoms/layout/Container';
import PageContentLayout from 'components/layouts/PageContentLayout';
import { PolicyQueryQuery } from '../gatsby/graphqlTypes';
import React from 'react';
import SlateContent from 'components/molecules/cms/SlateContent';
import { graphql } from 'gatsby';

export { Head } from 'components/atoms/Head';

export const query = graphql`
  query PolicyQuery($strapiId: Int!) {
    strapiPolicyPage(strapiId: { eq: $strapiId }) {
      c: contentSlate {
        ...SlateContent
      }
      title
      updatedAt
    }
  }
`;

type Props = AppPageProps<PolicyQueryQuery>;

const PolicyPage: React.FC<Props> = ({ data, pageContext }) => {
  const policy = data.strapiPolicyPage;
  if (!policy) {
    throw Error('No policy data');
  }
  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <Container narrow className="py-rhythm5">
        <Heading level={1}>{policy?.title}</Heading>
        <ContentCreationData
          lastEdited={policy?.updatedAt || Date.now().toString()}
        />
        <SlateContent content={policy.c} />
      </Container>
    </PageContentLayout>
  );
};

export default PolicyPage;
